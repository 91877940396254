import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { PlatformService } from '../../../../src/commons/services/business/platform.service'
import { addIcons } from 'ionicons'
import { closeOutline } from 'ionicons/icons'

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    private googleAnalyticsCategory = 'change_password'

    constructor(private modalController: ModalController,
        public platform: PlatformService,
        protected $gaService: GoogleAnalyticsService) {
        addIcons({ closeOutline })
    }

    ngOnInit(): void {
        //register GA event
        this.$gaService.event('my_profile_access', this.googleAnalyticsCategory)
    }

    public async dismissModal(): Promise<void> {
        await this.modalController.dismiss()
    }
}
