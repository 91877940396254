import { Component } from '@angular/core'
import { MenuController } from '@ionic/angular/standalone'
import { NavigationService } from '../services/business/navigation.service'
import { addIcons } from 'ionicons'
import { caretForwardOutline, caretDownOutline } from 'ionicons/icons'

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent {

    constructor(
        public navigationService: NavigationService,
        public menu: MenuController
    ) {
        void navigationService.loadMenus()
        addIcons({ caretForwardOutline, caretDownOutline })
    }

}
