import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { NotificationService } from '../services/ui/notification.service'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector
  ) { }

  handleError(error: Error | HttpErrorResponse): void {
    console.error(error)

    // Server Error
    //we will ignore 401 errors since those are treated separately
    if (error instanceof HttpErrorResponse && error.status !== 401) {
      const notificationService = this.injector.get(NotificationService)
      void notificationService.showUnhandledToasterErrorMessage()
    }
  }
}