<app-chat [isUnaunthenticatedChat]="false"></app-chat>
<ion-toolbar>
  <div class="logos">
    <div class="custom-logo">
      <div class="brand-logo"
        [ngClass]="brandedBottomNavigationColorIsBrightness ? 'brand-logo-dark' : 'brand-logo-white'">
      </div>
    </div>
    <div class="footer-links">
      <a *ngFor="let link of footerLinks" (click)="footerLinkClick(link)">{{ link.name }}</a>
    </div>
  </div>
  <ion-row *ngIf="bankDisclosure">
    <div class="bank-disclosure-box">
      <div class="bank-disclosure">
        {{ bankDisclosure }}
      </div>
    </div>
  </ion-row>
</ion-toolbar>
<div class="version"> Version {{ appVersion }}  <span *ngIf="isPreviewMode"> | Preview Mode: TRUE</span></div>