/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
    AbstractControlOptions,
    FormControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ForgotPasswordValidators } from '../validators/forgot-password.validators'
import { ActivatedRoute, Router } from '@angular/router'
import { PlatformService } from '../services/business/platform.service'
import { CacheService } from '../services/business/cache.service'
import { NotificationService } from '../services/ui/notification.service'
import { PasswordRule, PasswordRuleEvaluator, PasswordService } from '../../../src/security/services/passwordRules'
import { TokenType } from '../../../src/security/models'
import { UserApiService } from '../../../src/security/services/users/userApi.service'
import { Utils } from '../utils/utils'
import { GoogleAnalyticsEvents } from '../enums/google-analytics-events.enum'
import { addIcons } from 'ionicons'
import { checkmarkCircle } from 'ionicons/icons'

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
    @ViewChild('successMessage', { read: ElementRef }) successMessage: ElementRef

    private _timeoutHandler
    private _successColor = 'success'
    private _errorColor = 'danger'
    private _warningIcon = 'warning'
    private _checkmarkIcon = 'checkmark-circle'
    public forgotPasswordForm: UntypedFormGroup
    public platformClass: string
    public showPassword = false
    public showConfirmPassword = false
    public showError = false
    public isTokenValid = false
    public _isResetSuccessful = false
    public errorText
    public siteUrl = document.baseURI
    public validateAgainstToken = true
    public validatorMessages: {
        rule: string
        color: string
        icon: string
    }[] = []

    public get googleAnalyticsEvents(): typeof GoogleAnalyticsEvents {
        return GoogleAnalyticsEvents
    }

    public inputType(showPassword: boolean): string {
        return showPassword ? 'text' : 'password'
    }

    public passwordIconTitle(showPassword: boolean): string {
        return this.translate.instant(
            showPassword
                ? 'commons.changePassword.hidePassword'
                : 'commons.changePassword.showPassword'
        ) as string
    }

    public passwordIconName(showPassword: boolean): string {
        return showPassword ? 'eye-off-outline' : 'eye-outline'
    }

    public get showPasswordValidations(): boolean {
        return (
            this.forgotPasswordForm.controls['password'].dirty ||
            this.forgotPasswordForm.controls['password'].touched
        )
    }

    public get showPasswordConfirmValidations(): boolean {
        return (
            this.forgotPasswordForm.controls['confirmPassword'].dirty ||
            this.forgotPasswordForm.controls['confirmPassword'].touched
        )
    }

    public get passwordConfirmMatchColor(): string {
        return this.forgotPasswordForm.controls['confirmPassword'].hasError(
            'required'
        ) ||
            this.forgotPasswordForm.controls['confirmPassword'].hasError(
                'NoPassswordMatch'
            )
            ? this._errorColor
            : this._successColor
    }

    public get passwordConfirmMatchIcon(): string {
        return this.forgotPasswordForm.controls['confirmPassword'].hasError(
            'required'
        ) ||
            this.forgotPasswordForm.controls['confirmPassword'].hasError(
                'NoPassswordMatch'
            )
            ? this._warningIcon
            : this._checkmarkIcon
    }

    public get isPasswordValid(): boolean {
        return this.forgotPasswordForm.get('password').value && this.validatorMessages.filter(m => m.color == this._errorColor).length == 0
    }

    private checkValidationMessages(): void {
        if(this._timeoutHandler){
            clearTimeout(this._timeoutHandler)
        }

        //adding a delay process so we calculate user input
        this._timeoutHandler = setTimeout(() => {
            const passwordErrors = this.forgotPasswordForm.get('password').errors || []
            this.validatorMessages = this.passwordRules.map((passwordRule) => {
                return {
                    rule: this.translate.instant(
                        `commons.passwordRules.${passwordRule.id}`
                    ),
                    color:
                        passwordErrors &&
                            (passwordErrors['required'] || passwordErrors[passwordRule.id])
                            ? this._errorColor
                            : this._successColor,
                    icon:
                        passwordErrors &&
                            (passwordErrors['required'] || passwordErrors[passwordRule.id])
                            ? this._warningIcon
                            : this._checkmarkIcon,
                }
            })
        }, 300)
        
    }

    public get isFormVisible(): boolean {
        return this.isTokenValid && (!this._isResetSuccessful || !this.validateAgainstToken)
    }

    public passwordRules: PasswordRule[] = []

    constructor(
        private readonly translate: TranslateService,
        public platform: PlatformService,
        private fb: UntypedFormBuilder,
        private passwordService: PasswordService,
        private userApiService: UserApiService,
        private route: ActivatedRoute,
        private router: Router,
        private cacheService: CacheService,
        private notificationService: NotificationService,
        private translateService: TranslateService
    ) {
        this.setPasswordRules()
        this.forgotPasswordForm = this.createSignupForm()
        addIcons({ checkmarkCircle })
    }

    async ngOnInit(): Promise<void> {
        await this.validateToken()
    }

    public async validateToken(): Promise<void> {
        try {
            const forgotPasswordToken = this.route.snapshot.paramMap.get('token')
            if (forgotPasswordToken) {
                ForgotPasswordValidators.userToken = forgotPasswordToken
                const userFromToken = await this.userApiService.validateToken(
                    forgotPasswordToken,
                    TokenType.Forgotpassword,
                    this.cacheService.getPreviewMode()
                )
                this.isTokenValid = true
                if (this.isTokenValid) {
                    this.forgotPasswordForm.get('firstName').setValue(userFromToken.firstName)
                    this.forgotPasswordForm.get('lastName').setValue(userFromToken.lastName)
                    this.forgotPasswordForm.get('userId').setValue(Utils.removeUserPrefix(userFromToken.email))
                }
            }
            else {
                this.validateAgainstToken = false
                const account = this.cacheService.getSelectedAccount()
                const user = await this.cacheService.getUserInfo()

                if (account) {
                    this.isTokenValid = true
                    this.forgotPasswordForm.get('firstName').setValue(account.firstName)
                    this.forgotPasswordForm.get('lastName').setValue(account.lastName)
                    this.forgotPasswordForm.get('userId').setValue(Utils.removeUserPrefix(user.username))
                }
            }
        }
        catch (err) {
            this.isTokenValid = false
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            if (err?.status === 400) {
                this.errorText = 'forgotPasswordError'
            } else {
                this.errorText = 'InternalError'
            }
        }
    }

    backToLogin(): void {
        void this.router.navigate([''])
    }

    private showSuccessMessage() {
        this.errorText = ''
        void this.notificationService.showToasterMessage({
            message: this.translateService.instant('commons.changePassword.successMessage') as string,
        })
        this.forgotPasswordForm.reset()
    }

    public onSubmit(): void {
        if (this.validateAgainstToken) {
            this.userApiService
                .resetPassword(
                    this.route.snapshot.paramMap.get('token'),
                    this.forgotPasswordForm.get('password').value,
                    this.cacheService.getPreviewMode()
                )
                .then((response) => {
                    this._isResetSuccessful = response
                })
                .catch(() => {
                    this.errorText = 'passwordError'
                })
        }
        else {
            this.userApiService
                .changePassword(
                    this.forgotPasswordForm.get('password').value
                )
                .then((response) => {
                    this._isResetSuccessful = response
                    if (response) {
                        this.showSuccessMessage()
                    }
                    else {
                        this.errorText = 'passwordError'
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.errorText = 'passwordError'
                })
        }
    }

    public enterSubmit(event: KeyboardEvent): void {
        const target: HTMLElement = event.target as HTMLElement

        if (
            target &&
            target.nodeName === 'INPUT' &&
            this.forgotPasswordForm.valid &&
            (event.code === 'Enter' || event.code === 'NumpadEnter')
        ) {
            this.onSubmit()
        }
    }

    public showHidePassword(): void {
        this.showPassword = !this.showPassword
    }

    public showHideConfirmPassword(): void {
        this.showConfirmPassword = !this.showConfirmPassword
    }

    private setPasswordRules(): void {
        ForgotPasswordValidators.userApiService = this.userApiService
        ForgotPasswordValidators.passwordRuleEvaluator = new PasswordRuleEvaluator(
            ForgotPasswordValidators.updateRules
        )
        this.passwordService
            .getPasswordRules()
            .then((passwordRules) => {
                this.passwordRules = passwordRules
                ForgotPasswordValidators.passwordRuleEvaluator.addRules(passwordRules)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    private createSignupForm(): UntypedFormGroup {
        const passwordControl = new UntypedFormControl('', [
            Validators.required,
        ])

        passwordControl.valueChanges.subscribe((value) => {
            this.checkValidationMessages()
        })

        const confirmPasswordControl = new UntypedFormControl('', [
            Validators.required,
        ])

        confirmPasswordControl.valueChanges.subscribe((value) => {
            this.checkValidationMessages()
        })

        return this.fb.group(
            {
                userId: new UntypedFormControl('') as AbstractControlOptions,

                password:  passwordControl as AbstractControlOptions,

                confirmPassword: confirmPasswordControl as AbstractControlOptions,

                firstName: new UntypedFormControl('') as AbstractControlOptions,

                lastName: new UntypedFormControl('') as AbstractControlOptions,

                token: new UntypedFormControl('') as AbstractControlOptions,
            },
            {
                validator: [
                    ForgotPasswordValidators.passwordMatchValidator,
                    ForgotPasswordValidators.passwordFieldValidator,
                ],
            } as AbstractControlOptions
        )
    }
}
