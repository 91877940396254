import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'
import { CacheService } from '../services/business/cache.service'
import { CRBSettingKeys } from '../services/api/models/configuration.model'
import { PhonePipe } from '../directives/phone.pipe'
import { addIcons } from 'ionicons'
import { closeOutline } from 'ionicons/icons'

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {

    phoneNumber = ''

    constructor(
        private modalController: ModalController,
        private cacheService: CacheService,
        private phonePipe: PhonePipe
    ) {
        addIcons({ closeOutline })
    }

    ngOnInit(): void {
        const settings = this.cacheService.getBrandConfigSettings()
        const phoneNumber = settings?.find(q => q.keyName === CRBSettingKeys.BankSupportPhoneNumber)?.keyValue

        if (phoneNumber) {
            this.phoneNumber = this.phonePipe.transform(phoneNumber)
        }
    }

    public async dismissModal(): Promise<void> {
        await this.modalController.dismiss()
    }
}
