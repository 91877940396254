<form [formGroup]="forgotPasswordForm" *ngIf="isTokenValid" (ngSubmit)="onSubmit()" (keydown)="enterSubmit($event)"
  gaCategory="forget_password">
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-item #successMessage lines="none" [hidden]="true" expand="block">
        <span class="success-message">{{ "commons.changePassword.successMessage" | translate }}</span>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-text *ngIf="isFormVisible">
        <p>{{'commons.changePassword.passwordResetInfo' | translate}}</p>
      </ion-text>
      <ion-text *ngIf="!isFormVisible">
        <p>{{'commons.changePassword.passwordResetSuccess' | translate}}</p>
      </ion-text>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="isFormVisible">
    <ion-row>
      <ion-col>
        <ion-item class="textfield" lines="none">
          <ion-input id="passwordInput" [type]="inputType(showPassword)"
            placeholder="{{'commons.changePassword.password' | translate}}" clearOnEdit="false"
            formControlName="password"></ion-input>
          <ion-icon aria-label="OK" *ngIf="isPasswordValid" id="passwordInput1Ok" [slot]="'end'" class="success"
            name="checkmark-circle"></ion-icon>
          <ion-icon aria-hidden="true" id="showHidePasswordIcon" [slot]="'end'" [title]="passwordIconTitle(showPassword)"
            [name]="passwordIconName(showPassword)" (click)="showHidePassword()"></ion-icon>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list *ngIf="showPasswordValidations">
          <ion-item *ngFor="let rule of validatorMessages" class="validations" lines="none">
            <ion-text position="stacked" [color]="rule.color">
              <ion-icon aria-hidden="true" [name]="rule.icon"></ion-icon>
              {{rule.rule}}
            </ion-text>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item class="textfield" lines="none">
          <ion-input id="reEnterPasswordInput" [type]="inputType(showConfirmPassword)"
            placeholder="{{'commons.changePassword.re-enterPassword' | translate}}" clearOnEdit="false"
            formControlName="confirmPassword"></ion-input>
          <ion-icon aria-label="OK" *ngIf="showPasswordConfirmValidations && passwordConfirmMatchColor === 'success'"
            id="passwordInput2Ok" [slot]="'end'" class="success" name="checkmark-circle"></ion-icon>
          <ion-icon aria-hidden="true" id="showHideReEnterPasswordIcon" [slot]="'end'" [title]="passwordIconTitle(showConfirmPassword)"
            [name]="passwordIconName(showConfirmPassword)" (click)="showHideConfirmPassword()"></ion-icon>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list *ngIf="showPasswordConfirmValidations">
      <ion-item class="validations" lines="none">
        <ion-text position="stacked" [color]="passwordConfirmMatchColor">
          <ion-icon attr.aria-label="{{'commons.changePassword.passwordMatch' | translate}}" [name]="passwordConfirmMatchIcon"></ion-icon>
          {{'commons.changePassword.passwordMatch' | translate}}
        </ion-text>
      </ion-item>
    </ion-list>
    <ion-row *ngIf="errorText === 'passwordError'">
      <div class="ion-text-center text-danger error-change-message">
        <h6>{{'commons.changePassword.passwordError' | translate }}</h6>
      </div>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button class="submit-button" [gaEvent]="googleAnalyticsEvents.NewPassword" id="registerButton" type="submit"
          [disabled]="!forgotPasswordForm.valid">
          {{ 'commons.changePassword.submit' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row>
    <ion-col class="ion-no-padding" *ngIf="!isFormVisible">
      <ion-button id="backToLoginButton" expand="block" (click)="backToLogin()">
        {{ 'commons.changePassword.backToLogin' | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</form>
<div *ngIf="!validateAgainstToken && _isResetSuccessful" class="ion-text-center text-success success-change-message">
  <h6>{{'commons.changePassword.successMessage' | translate }}</h6>
</div>
<div *ngIf="!isTokenValid && errorText === 'InternalError'" class="token-message ion-text-center">
  <h3>{{'commons.tokenErrors.InternalError' | translate }}</h3>
</div>
<div *ngIf="!isTokenValid && errorText === 'forgotPasswordError'" class="token-message ion-text-center">
  <h3>{{'commons.tokenErrors.forgotPasswordError' | translate }}</h3>
</div>