/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Injectable } from '@angular/core'
import { UtilsService } from '../business/utils.service'
import { ApiService } from '../api/api.service'
import { BrandFileKeys, FileDto, UniqueFileKeys, UpsertFileDto } from '../api/models/file.model'
import { CacheService } from '../business/cache.service'
import { BrandColorKeys } from '../api/models/configuration.model'

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private apiService: ApiService,
    private cacheService: CacheService) {
  }

  async setBrand(): Promise<void> {
    await this.setFiles()
    await this.setColors()
  }

  get origin(): string {
    return window.location.origin.indexOf('localhost') >= 0 ?
      'https://fd-slp-crb-dev-hmbuf0aseqb0fwbg.a01.azurefd.net' : window.location.origin
  }

  async setFiles(brandFiles: FileDto[] = null): Promise<void> {
    const files = brandFiles || await this.apiService.getBrandFiles(encodeURIComponent(this.origin))

    this.cacheService.setBrandFiles(files)

    if (files) {
      for (let i = 0; i < UniqueFileKeys.length; i++) {
        const brandFile = UniqueFileKeys[i]
        const apiFile = files.find(q => q.key == brandFile)
        if (apiFile) {
          document.documentElement.style.setProperty(`--${brandFile}`, `url('${apiFile.url}')`)
        } else {
          document.documentElement.style.setProperty(`--${brandFile}`, '')
        }
      }

      //marketing banner images
      UtilsService.marketingImages = files.filter(q => q.key == BrandFileKeys.MarketingBanner).map(q => q.url)

      //favicon
      UtilsService.favicon = files.find(q => q.key == BrandFileKeys.Favicon)?.url
      UtilsService.FaviconChangeEvent.emit()
    }
  }

  async setColors(colors: BrandColors = null): Promise<void> {
    this.resetColorsToDefault()
    const brandColors = colors ?? await this.getBrandColors()

    if (brandColors.globalHeaderColor)
      document.documentElement.style.setProperty('--globalHeaderColor', brandColors.globalHeaderColor)

    if (brandColors.callToActionColor)
      document.documentElement.style.setProperty('--callToActionColor', brandColors.callToActionColor)

    if (brandColors.topNavigationBarColor)
      document.documentElement.style.setProperty('--topNavigationBarColor', brandColors.topNavigationBarColor)

    if (brandColors.activeNavFunctionColor)
      document.documentElement.style.setProperty('--activeNavFunctionColor', brandColors.activeNavFunctionColor)

    if (brandColors.secondaryFunctionColor)
      document.documentElement.style.setProperty('--secondaryFunctionColor', brandColors.secondaryFunctionColor)

    if (brandColors.moduleHeaderColor)
      document.documentElement.style.setProperty('--moduleHeaderColor', brandColors.moduleHeaderColor)

    if (brandColors.moduleHeaderTextColor)
      document.documentElement.style.setProperty('--moduleHeaderTextColor', brandColors.moduleHeaderTextColor)

    if (brandColors.bottomNavigationColor)
      document.documentElement.style.setProperty('--bottomNavigationColor', brandColors.bottomNavigationColor)

    if (brandColors.socialMediaIconColor)
      document.documentElement.style.setProperty('--socialMediaIconColor', brandColors.socialMediaIconColor)

    if (brandColors.dividerLineColor)
      document.documentElement.style.setProperty('--dividerLineColor', brandColors.dividerLineColor)

    if (brandColors.mainTextColor)
      document.documentElement.style.setProperty('--mainTextColor', brandColors.mainTextColor)

    this.setIonicColors(brandColors)
    this.checkColorBrightness()
  }

  private resetColorsToDefault(): void {
    document.documentElement.style.setProperty('--globalHeaderColor', NonBrandedColors.globalHeaderColor)
    document.documentElement.style.setProperty('--callToActionColor', NonBrandedColors.callToActionColor)
    document.documentElement.style.setProperty('--topNavigationBarColor', NonBrandedColors.topNavigationBarColor)
    document.documentElement.style.setProperty('--activeNavFunctionColor', NonBrandedColors.activeNavFunctionColor)
    document.documentElement.style.setProperty('--secondaryFunctionColor', NonBrandedColors.secondaryFunctionColor)
    document.documentElement.style.setProperty('--moduleHeaderColor', NonBrandedColors.moduleHeaderColor)
    document.documentElement.style.setProperty('--moduleHeaderTextColor', NonBrandedColors.moduleHeaderTextColor)
    document.documentElement.style.setProperty('--bottomNavigationColor', NonBrandedColors.bottomNavigationColor)
    document.documentElement.style.setProperty('--socialMediaIconColor', NonBrandedColors.socialMediaIconColor)
    document.documentElement.style.setProperty('--dividerLineColor', NonBrandedColors.dividerLineColor)
    document.documentElement.style.setProperty('--mainTextColor', NonBrandedColors.mainTextColor)

    this.checkColorBrightness()
  }

  async getBrandFiles(domain: string = null, previewModel = false): Promise<FileDto[]> {
    return await this.apiService.getBrandFiles(domain || encodeURIComponent(this.origin), previewModel)
  }

  async removeFile(fileId: number): Promise<void> {
    await this.apiService.deleteBrandFile(fileId)
  }

  async getBrandColors(domain: string = null, previewModel = false): Promise<BrandColors> {
    // - call API to get colors
    const brandConfig = await this.apiService.getBrandConfigSettings(domain || encodeURIComponent(this.origin), previewModel)
    this.cacheService.setBrandConfigSettings(brandConfig)

    const colorSettings = brandConfig.filter(x => BrandColorKeys.includes(x.keyName))
    //Non-branded
    const colors = {
      'globalHeaderColor': NonBrandedColors.globalHeaderColor,
      'callToActionColor': NonBrandedColors.callToActionColor,
      'topNavigationBarColor': NonBrandedColors.topNavigationBarColor,
      'activeNavFunctionColor': NonBrandedColors.activeNavFunctionColor,
      'secondaryFunctionColor': NonBrandedColors.secondaryFunctionColor,
      'moduleHeaderColor': NonBrandedColors.moduleHeaderColor,
      'moduleHeaderTextColor': NonBrandedColors.moduleHeaderTextColor,
      'bottomNavigationColor': NonBrandedColors.bottomNavigationColor,
      'socialMediaIconColor': NonBrandedColors.socialMediaIconColor,
      'dividerLineColor': NonBrandedColors.dividerLineColor,
      'mainTextColor': NonBrandedColors.mainTextColor,
    } as BrandColors

    if (colorSettings.length) {
      colorSettings.forEach(function (color) {
        colors[color.keyName] = color.keyValue
      })
    }

    return colors
  }

  async updateBrandFile(file: UpsertFileDto): Promise<number> {
    return this.apiService.updateBrandFile(file)
  }

  async publishBrandConfiguration(crbConfigRId: number, programCode: string): Promise<boolean> {
    return this.apiService.publishBrandConfiguration(crbConfigRId, programCode)
  }

  async updateBrandConfigSettings(configSettings: { [key: string]: string }): Promise<void> {
    return this.apiService.updateBrandConfigSettings(configSettings)
  }

  private setIonicColors(brandedColors: BrandColors): void {
    /** primary **/
    const primaryRGB = this.hexToRgb(brandedColors.callToActionColor)
    const primaryContrast = this.getColorBrightness(brandedColors.callToActionColor) < 138 ? '#ffffff' : '#000000'
    const primaryContrastRGB = this.hexToRgb(primaryContrast)

    document.documentElement.style.setProperty('--ion-color-primary', brandedColors.callToActionColor)
    document.documentElement.style.setProperty('--ion-color-primary-rgb', `${primaryRGB.r}, ${primaryRGB.g}, ${primaryRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-primary-contrast', primaryContrast)
    document.documentElement.style.setProperty('--ion-color-primary-contrast-rgb', `${primaryContrastRGB.r}, ${primaryContrastRGB.g}, ${primaryContrastRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-primary-shade', this.darkenColor(brandedColors.callToActionColor, 10))
    document.documentElement.style.setProperty('--ion-color-primary-tint', this.lightenColor(brandedColors.callToActionColor, 10))

    /** secondary **/
    const secondaryRGB = this.hexToRgb(brandedColors.secondaryFunctionColor)
    const secondaryContrast = this.getColorBrightness(brandedColors.secondaryFunctionColor) < 138 ? '#ffffff' : '#000000'
    const secondaryContrastRGB = this.hexToRgb(secondaryContrast)

    document.documentElement.style.setProperty('--ion-color-secondary', brandedColors.secondaryFunctionColor)
    document.documentElement.style.setProperty('--ion-color-secondary-rgb', `${secondaryRGB.r}, ${secondaryRGB.g}, ${secondaryRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-secondary-contrast', secondaryContrast)
    document.documentElement.style.setProperty('--ion-color-secondary-contrast-rgb', `${secondaryContrastRGB.r}, ${secondaryContrastRGB.g}, ${secondaryContrastRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-secondary-shade', this.darkenColor(brandedColors.secondaryFunctionColor, 10))
    document.documentElement.style.setProperty('--ion-color-secondary-tint', this.lightenColor(brandedColors.secondaryFunctionColor, 10))

    /** tertiary **/
    const tertiaryRGB = this.hexToRgb(brandedColors.moduleHeaderColor)
    const tertiaryContrast = this.getColorBrightness(brandedColors.moduleHeaderColor) < 138 ? '#ffffff' : '#000000'
    const tertiaryContrastRGB = this.hexToRgb(tertiaryContrast)

    document.documentElement.style.setProperty('--ion-color-tertiary', brandedColors.moduleHeaderColor)
    document.documentElement.style.setProperty('--ion-color-tertiary-rgb', `${tertiaryRGB.r}, ${tertiaryRGB.g}, ${tertiaryRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast', tertiaryContrast)
    document.documentElement.style.setProperty('--ion-color-tertiary-contrast-rgb', `${tertiaryContrastRGB.r}, ${tertiaryContrastRGB.g}, ${tertiaryContrastRGB.b}`)
    document.documentElement.style.setProperty('--ion-color-tertiary-shade', this.darkenColor(brandedColors.moduleHeaderColor, 10))
    document.documentElement.style.setProperty('--ion-color-tertiary-tint', this.lightenColor(brandedColors.moduleHeaderColor, 10))
  }

  private checkColorBrightness() {
    // Check the brightness of the globalHeaderColor
    if (this.getColorBrightness(document.documentElement.style.getPropertyValue('--globalHeaderColor')) > 125) {
      UtilsService.brandedGlobalHeaderColorIsBrightness = true
    } else {
      UtilsService.brandedGlobalHeaderColorIsBrightness = false
    }

    // Check the brightness of the bottomNavigationColor
    if (this.getColorBrightness(document.documentElement.style.getPropertyValue('--bottomNavigationColor')) > 125) {
      UtilsService.brandedBottomNavigationColorIsBrightness = true
    } else {
      UtilsService.brandedBottomNavigationColorIsBrightness = false
    }
  }

  private getColorBrightness(hexColor: string) {
    const rgb = this.hexToRgb(hexColor)

    const brightness = Math.round(((rgb.r * 299) +
      (rgb.g * 587) +
      (rgb.b * 114)) / 1000)

    return brightness
  }

  private hexToRgb(hex) {
    // Expand shorthand form (e.g. '03F') to full form (e.g. '0033FF')
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (r: number, g: number, b: number) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }

  private darkenColor(color, amount) {
    const parsedColor = this.parseColor(color)
    const darkenedColor = {
      r: Math.max(parsedColor.r - amount, 0),
      g: Math.max(parsedColor.g - amount, 0),
      b: Math.max(parsedColor.b - amount, 0),
    }
    return this.formatColor(darkenedColor)
  }

  private lightenColor(color, amount) {
    const parsedColor = this.parseColor(color)
    const lightenedColor = {
      r: Math.min(parsedColor.r + amount, 255),
      g: Math.min(parsedColor.g + amount, 255),
      b: Math.min(parsedColor.b + amount, 255),
    }
    return this.formatColor(lightenedColor)
  }

  private parseColor(color) {
    const hex = color.replace(/^#/, '')
    const bigint = parseInt(hex, 16)
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    }
  }

  private formatColor(color) {
    const { r, g, b } = color
    return `rgb(${r}, ${g}, ${b})`
  }
}

export class NonBrandedColors {
  public static globalHeaderColor = '#222222'         // 1-System-Black
  public static callToActionColor = '#ed3186'         // Primary-HotPink
  public static topNavigationBarColor = '#2f2e41'     // Primary-Charcoal
  public static activeNavFunctionColor = '#fd8924'    // PartnerColor-01
  public static secondaryFunctionColor = '#ffffff'    // 1-System-White
  public static moduleHeaderColor = '#1dade4'         // Primary-BrightBlue
  public static moduleHeaderTextColor = '#ffffff'     // 1-System-White
  public static bottomNavigationColor = '#2f2e41'     // Primary-Charcoal
  public static socialMediaIconColor = '#ffffff'      // 1-System-White
  public static dividerLineColor = '#B9B9B9'          // 1-Gray
  public static mainTextColor = '#222222'             // 1-System-Black
}

export class BrandColors {
  globalHeaderColor: string
  callToActionColor: string
  topNavigationBarColor: string
  activeNavFunctionColor: string
  secondaryFunctionColor: string
  moduleHeaderColor: string
  moduleHeaderTextColor: string
  bottomNavigationColor: string
  socialMediaIconColor: string
  dividerLineColor: string
  mainTextColor: string
}