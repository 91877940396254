/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Component, Input } from '@angular/core'
import { AbstractControl, ValidationErrors } from '@angular/forms'
import { ValidationMessageService } from '../services/validation-message.service'
import { addIcons } from 'ionicons'
import { warning } from 'ionicons/icons'

@Component({
    selector: 'validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent {

    @Input() control: AbstractControl

    constructor(
        public readonly validationMessageService: ValidationMessageService,
    ) {
        addIcons({ warning })
    }

    get validationMessage(): string {
        if (this.control?.status === 'INVALID' && this.control?.touched) {
            for (const propertyName in this.control.errors) {
                const params = this.getParams(this.control.errors)
                return this.validationMessageService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName], params)
            }
        }
        return null
    }

    getParams(errors: ValidationErrors): any {
        let params = null
        if (errors.maxlength) {
            params = { 'length': errors.maxlength.requiredLength }
        } else if (errors.minlength) {
            params = { 'length': errors.minlength.requiredLength }
        }
        return params
    }

}
