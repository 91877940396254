/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit } from '@angular/core'
import { MaskPipe } from '../directives/mask.pipe'

@Component({
    selector: 'app-account-number',
    templateUrl: './account-number.component.html',
    styleUrls: ['./account-number.component.scss']
})
export class AccountNumberComponent implements OnInit {
    @Input()
    public allowUnMask = true
    public accountIdentifier = ''
    private isMasked = true

    @Input() cssStyle = ''

    @Input() public set accountNumber(value: string) {
        this.accountIdentifier = value
    }

    constructor(private maskPipe: MaskPipe) { }

    ngOnInit(): void {
        this.isMasked = true
    }

    public showHideAccountNumber(event: any): void {
        this.isMasked = !this.isMasked
        event?.stopPropagation()
    }

    public get accountNumberIconName(): string {
        return !this.isMasked ? 'eye-off-outline' : 'eye-outline'
    }

    public get accountNumberValue(): string {
        if (!this.isMasked) {
            return this.accountIdentifier
        }
        else {
            return this.maskPipe.transform(this.accountIdentifier)
        }
    }
}
