import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'
import { PlatformService } from '../services/business/platform.service'
import { addIcons } from 'ionicons'
import { closeCircleOutline } from 'ionicons/icons'

@Component({
    selector: 'app-html-content-modal',
    templateUrl: './html-content-modal.component.html',
    styleUrls: ['./html-content-modal.component.scss'],
})
export class HtmlContentModalComponent {
    public modalHtmlUrl = ''
    public showCloseButton = true
    public safeResourceUrl: SafeResourceUrl

    constructor(public platform: PlatformService,
        public modalController: ModalController,
        public sanitizer: DomSanitizer) {
        setTimeout(() => {
            this.safeResourceUrl = sanitizer.bypassSecurityTrustResourceUrl(this.modalHtmlUrl)
        }, 100)
        addIcons({ closeCircleOutline })
    }

    public async dismiss(): Promise<void> {
        const modal = await this.modalController.getTop()
        if (modal) {
            await modal.dismiss()
        }
    }
}
