<ion-breadcrumbs class="desktop-breadcrumb">
  <ion-breadcrumb [routerLink]="'/home'">Home</ion-breadcrumb>
  <ion-breadcrumb *ngFor="let item of menuItems" [href]="item.url"> {{ item.label }}</ion-breadcrumb>
</ion-breadcrumbs>

<div class="mobile-breadcrumb">
  <ion-item lines="none" class="ion-no-padding mobile-breadcrumb-item">
    <ion-button *ngIf="menuItems" class="back-button" (click)="goBack()">
      <ion-icon name="chevron-back"></ion-icon><span>{{ "commons.myProfile.back" | translate }}</span>
    </ion-button>
    <ion-label *ngIf="menuItems">
      {{ menuItems[menuItems.length -1]?.label }}
      {{ customText }}
    </ion-label>
  </ion-item>
</div>