/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CommonModule, DatePipe } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { NgxColorsModule } from 'ngx-colors'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ApiServiceBase, ModelMiscModule } from '@sightline/models-misc'
import { environment } from '../environments/environment'
import { PhoneMaskDirective } from './directives/phone-mask.directive'
import { FooterComponent } from './footer/footer.component'
import { HeaderComponent } from './header/header.component'
import { MyProfileComponent } from './header/my-profile/my-profile.component'
import { IdleMessageComponent } from './idle-message/idle-message.component'
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component'
import { ApiService } from './services/api/api.service'
import { CacheService } from './services/business/cache.service'
import { PlatformService } from './services/business/platform.service'
import { UtilsService } from './services/business/utils.service'
import { NotificationService } from './services/ui/notification.service'
import { SlpDatePickerComponent } from './slp-date-picker/slp-date-picker.component'
import { SlpSearchComponent } from './slp-search/slp-search.component'
import { ClickNoPropagateDirective } from './directives/click-no-propagate.directive'
import { ValidationMessagesComponent } from './validators/validation-messages/validation-messages.component'
import { GlobalErrorHandler } from './error-handling/global-error-handler'
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component'
import { ChatComponent } from './chat/chat.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NumberDirective } from './directives/number.directive'
import { DateDirective } from './directives/date.directive'
import { BrandService } from './services/ui/brandService.service'
import { LanguageTranslationService } from './services/ui/languageTranslation.service'
import { PasswordChangeComponent } from './password-change/password-change.component'
import { ChangePasswordComponent } from './header/change-password/change-password.component'
import { SwiperModule } from 'swiper/angular'
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component'
import { PhonePipe } from './directives/phone.pipe'
import { SafePipe } from './directives/safe.pipe'
import { MaskPipe } from './directives/mask.pipe'
import { TabMonitorComponent } from './tab-monitor/tab-monitor.component'
import { HtmlContentModalComponent } from './html-content-modal/html-content-modal.component'
import { NavigationService } from './services/business/navigation.service'
import { AuthInterceptor } from '../../src/security/services/auth'
import { API_CONFIG } from '../../src/security/services/users/userApi.service'
import { ContactUsComponent } from './contact-us/contact-us.component'
import { AccountNumberComponent } from './account-number/account-number.component'
import { AddCardToWalletComponent } from './add-card-to-wallet/add-card-to-wallet.component'
import { TitleService } from './services/ui/title.service'
import { CustomAlertComponent } from './custom-alert/custom-alert.component'
import { AccountsService } from './services/business/accounts.service'
import { HtmlPrivacyPolicyModalComponent } from './html-privacy-policy-modal/html-privacy-policy-modal.component'
import { StyleConfiguratorComponent } from './style-configurator/style-configurator.component'
import { NgxMatFileInputModule } from '@angular-material-components/file-input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { IonLabel, IonIcon, IonHeader, IonItem, IonButton, IonContent, IonRow, IonCol, IonBreadcrumbs, IonBreadcrumb, IonFab, IonFabButton, IonToolbar, IonMenuButton, IonPopover, IonMenu, IonTitle, IonList, IonText, IonInput, IonDatetime, IonGrid, IonToggle, IonNote } from '@ionic/angular/standalone'
import { NotificationManagementComponent } from './notification-management/notification-management.component'

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        RouterModule,
        ModelMiscModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SwiperModule,
        NgxColorsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        NgxMatFileInputModule,
        MatCheckboxModule,
        IonLabel,
        IonIcon,
        IonHeader,
        IonItem,
        IonButton,
        IonContent,
        IonRow,
        IonCol,
        IonBreadcrumbs,
        IonBreadcrumb,
        IonFab,
        IonFabButton,
        IonToolbar,
        IonMenuButton,
        IonPopover,
        IonMenu,
        IonTitle,
        IonList,
        IonText,
        IonInput,
        IonDatetime,
        IonGrid,
        IonToggle,
        IonNote,
    ],
    declarations: [
        FooterComponent,
        HtmlContentModalComponent,
        NotificationManagementComponent,
        HtmlPrivacyPolicyModalComponent,
        HeaderComponent,
        MobileMenuComponent,
        IdleMessageComponent,
        MyProfileComponent,
        ContactUsComponent,
        AddCardToWalletComponent,
        PhoneMaskDirective,
        SlpDatePickerComponent,
        SlpSearchComponent,
        ClickNoPropagateDirective,
        ValidationMessagesComponent,
        LoadingPlaceholderComponent,
        ChatComponent,
        NumberDirective,
        DateDirective,
        PhonePipe,
        SafePipe,
        MaskPipe,
        PasswordChangeComponent,
        ChangePasswordComponent,
        BreadcrumbComponent,
        TabMonitorComponent,
        AccountNumberComponent,
        CustomAlertComponent,
        StyleConfiguratorComponent
    ],
    exports: [
        FooterComponent,
        HtmlContentModalComponent,
        NotificationManagementComponent,
        HtmlPrivacyPolicyModalComponent,
        TranslateModule,
        HeaderComponent,
        MobileMenuComponent,
        IdleMessageComponent,
        MyProfileComponent,
        ContactUsComponent,
        AddCardToWalletComponent,
        PhoneMaskDirective,
        SlpDatePickerComponent,
        SlpSearchComponent,
        ClickNoPropagateDirective,
        ValidationMessagesComponent,
        LoadingPlaceholderComponent,
        PasswordChangeComponent,
        ChatComponent,
        NumberDirective,
        DateDirective,
        PhonePipe,
        SafePipe,
        MaskPipe,
        SwiperModule,
        BreadcrumbComponent,
        TabMonitorComponent,
        AccountNumberComponent,
        CustomAlertComponent,
        StyleConfiguratorComponent
    ],
    providers: [
        ApiServiceBase,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: ApiService, deps: [ApiServiceBase, API_CONFIG] },
        {
            provide: API_CONFIG,
            useValue: {
                url: environment.apiUrl,
                version: environment.apiVersion,
            },
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        DatePipe,
        MaskPipe,
        PhonePipe,
        PlatformService,
        UtilsService,
        CacheService,
        NotificationService,
        BrandService,
        LanguageTranslationService,
        NavigationService,
        TitleService,
        AccountsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonsModule { }
