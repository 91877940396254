/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-floating-promises */

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { AuthWrapperService } from './auth-wrapper.service'


export const LOGIN_URL = 'auth/login'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router, private authWrapper: AuthWrapperService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    let isAuthenticated = false
    try {
      isAuthenticated = await this.authWrapper.isAuthenticated()
    } catch {
      // this error could happen when changing one page with an expired token
      // so this is fired before the auth.interceptor.ts
      try {
        void await this.authWrapper.refreshToken()
        isAuthenticated = await this.authWrapper.isAuthenticated()
      } catch {
        // do nothing - isAuthenticated = false
      }
    }

    if (isAuthenticated) {
      return true
    } else {
      this.router.navigate([LOGIN_URL])
      return false
    }
  }
}
