/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AfterContentInit, Component, Inject, Input, Renderer2 } from '@angular/core'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { Account } from '../../../src/app/account/models/account.model'
import { CacheService } from '../services/business/cache.service'
import { PlatformService } from '../services/business/platform.service'
import { GoogleAnalyticsEvents } from '../enums/google-analytics-events.enum'
import { environment } from '../../../src/environments/environment'
import { DOCUMENT } from '@angular/common'
import { ApiService } from '../services/api/api.service'
import { Router } from '@angular/router'

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterContentInit {

    faMessage = faComments
    chatWithUserMessage: string

    @Input() isUnaunthenticatedChat = false

    constructor(
        private translate: TranslateService,
        public platform: PlatformService,
        private cacheService: CacheService,
        protected gaService: GoogleAnalyticsService,
        private _renderer2: Renderer2,
        private readonly apiService: ApiService,
        private router: Router,
        @Inject(DOCUMENT) private _document: Document,
    ) {

    }

    public get googleAnalyticsEvents(): typeof GoogleAnalyticsEvents {
        return GoogleAnalyticsEvents
    }

    get chatCss(): string {
        return this.isUnaunthenticatedChat ? 'start_chat_unauthenticated' : 'start_chat_authenticated'
    }

    ngAfterContentInit(): void {
        if (this.isUnaunthenticatedChat) {
            this.chatWithUserMessage = this.translate.instant('authentication.chat.chatWithUs') as string
        }
        else {
            const chatScript = this._document.getElementById('spaceChatModule')

            //if chat is unauthenticated we need to switch to authenticated
            if (chatScript && chatScript['src'] === environment.spaceChat) {
                chatScript.remove()

                this._document.getElementById('amazon-connect-chat-widget')?.remove()

                const script = this._renderer2.createElement('script')
                script.id = 'spaceChatModule'
                script.type = 'application/javascript'
                script.src = environment.spaceAuthenticatedChat

                this._renderer2.appendChild(this._document.body, script)
            }
        }

        if (this.setPaceChatVariables) {
            this.setPaceChatVariables(this.cacheService.getSelectedAccount())
        }
    }

    private setPaceChatVariables(selectedAccount: Account): void {
        if (window['ChatModule']) {
            window['ChatModule'].setChatAttribute('programCode', this.cacheService.getCRBConfig()?.programCode)

            if (!this.isUnaunthenticatedChat && selectedAccount) {
                window['ChatModule'].setChatAttribute('memberId', selectedAccount.masterMemberNumber)
                window['ChatModule'].setDisplayName(selectedAccount.firstName)

                this.translate.get('home.chat.chatWithUs').subscribe((translated: string) => {
                    this.chatWithUserMessage = translated
                })
            }
            else {
                window['ChatModule'].setChatAttribute('memberId', '')
                window['ChatModule'].setDisplayName('')
            }
        }
    }

    public async onStartChat(): Promise<void> {
        if (Object.keys(window['ChatModule'])) {
            this.setPaceChatVariables(this.cacheService.getSelectedAccount())
        }

        this.gaService.event('start_chat', 'pace_chat')
        const chatButton = document.getElementById('amazon-connect-chat-widget')?.getElementsByTagName('button')[0]

        if (chatButton) {
            chatButton.click()

            if (!this.isUnaunthenticatedChat) {
                try {
                    await this.apiService.logChatInitiated(this.router.url)
                }
                catch (e) {
                    console.log(e)
                }
            }
        }
    }
}
