import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'

@Component({
    selector: 'app-custom-alert',
    templateUrl: './custom-alert.component.html',
    styleUrls: ['./custom-alert.component.scss'],
})
export class CustomAlertComponent {

    headerText: string
    messageText: string

    actionButtons: ActionButton[]

    constructor(private modalController: ModalController) { }

    executeAction(actionButton: ActionButton): void {
        void this.modalController.dismiss()

        if (actionButton.action)
            actionButton.action()
    }

}

export class ActionButton {
    text: string
    cssClass?: string
    color?: string
    action?: () => void
}
