<ion-content aria-label="PRIVACY NOTICE">
  <div class="header"><ion-icon (click)="dismiss()" name="close-circle-outline"></ion-icon></div>

  <div class="toggle-container">
    <button id="left-button" (click)="showPrivaceNotice(1)" color="primary" class="toggle-button"
      [ngClass]="{'active': privacyPolicyType === 1}">{{crbConfigName}} PRIVACY NOTICE</button>
    <button id="right-button" (click)="showPrivaceNotice(2)" color="primary" class="toggle-button"
      [ngClass]="{'active': privacyPolicyType === 2}">CRB PRIVACY NOTICE</button>
  </div>

  <iframe *ngIf="this.safeResourceUrl" [src]="safeResourceUrl" class="dynamic-content"></iframe>
</ion-content>