/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Directive, ElementRef, HostListener } from '@angular/core'
import { NgControl } from '@angular/forms'
import { Utils } from '../utils/utils'

@Directive({
  selector: '[appDate]',
})
export class DateDirective {
  constructor(public ngControl: NgControl, public el: ElementRef) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if (event === null) {
      return
    }

    let newVal = event.replace(/\D/g, '')

    if (newVal.length === 0) {
      newVal = ''
    } else if (newVal.length <= 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1')
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2')
    } else {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3')
    }

    if (newVal.length === 0) {
      newVal = ''
    }

    this.ngControl.valueAccessor.writeValue(newVal)
    const date = Utils.createDateFromFormatMMDDYYYY(newVal)
    let validDate = '' 

    if (newVal.length === 'MM/DD/YYYY'.length){
      validDate = Utils.formatDateToMMDDYYYY(date)
    }

    if (newVal !== validDate) {
      this.ngControl.control.setErrors({ invalidDate: true })
    }else if(newVal){
      this.ngControl.control.setErrors(null)
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const isDeleteKey = event.code === 'Delete'
    const isBackspaceKey = event.code === 'Backspace'
    const isDigit = event.key >= '0' && event.key <= '9'

    if (!isBackspaceKey && !isDeleteKey && !isDigit) return

    const cursorPosition: number = this.el.nativeElement.children[0].selectionStart
    let newCursorPosition: number = cursorPosition

    // Reposition the caret when slashes are added or removed
    if (this.el.nativeElement.children[0] != undefined) {
      const newVal = this.el.nativeElement.children[0].value

      if (isBackspaceKey && cursorPosition === 3 && newVal.length < 4) {
        newCursorPosition = cursorPosition - 1
      } else if (cursorPosition === 3 && newVal.length === 4) {
        newCursorPosition = 4
      } else if (cursorPosition === 6 && newVal.length === 7 && !isBackspaceKey && !isDeleteKey) {
        newCursorPosition = 7
      }

      this.el.nativeElement.children[0].setSelectionRange(newCursorPosition, newCursorPosition)
    }
  }
}
