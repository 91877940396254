/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'
import { CacheService } from '../services/business/cache.service'
import { AuthWrapperService } from '../../../src/security/services/auth'

@Component({
    selector: 'app-idle-message',
    templateUrl: './idle-message.component.html',
    styleUrls: ['./idle-message.component.scss'],
})
export class IdleMessageComponent {
    public time: string

    constructor(
        public modalController: ModalController,
        public authWrapperService: AuthWrapperService,
        public cacheService: CacheService
    ) { }

    public async renewSession(): Promise<void> {
        try {
            this.authWrapperService.renewIdleSession()
            void await this.authWrapperService.refreshToken()
        } catch (e) {
            console.log(e)
            this.logOut()
        }
    }

    logOut(): void {
        void this.cacheService.clearLocalStorageCache()
        void this.authWrapperService.logOut()
    }

    getTime = (): string => this.authWrapperService.countDownToLogout
}
