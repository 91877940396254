<ion-header gaCategory="notifications_management_header">
  <ion-item lines="none" class="header-item">
    <ion-button slot="end" class="header-buttons" (click)="dismissModal()">
      <ion-icon name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item lines="none" class="title-item">
    <ion-label>
      {{ "commons.notificationsManagement.title" | translate }}
    </ion-label>
  </ion-item>
</ion-header>
<ion-content gaCategory="notifications_management">

  <div class="loading-form" *ngIf="loading">
    <app-loading-placeholder [type]="3"></app-loading-placeholder>
  </div>
  <div class="modal-info" *ngIf="!loading">
    <ion-row>
      <ion-col size="12">
        <ion-item lines="none" class="modal-ion-item">
          <ion-label class="modal-label notification-management-message">
            {{ "commons.notificationsManagement.message" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let notification of customerNotifications">
      <ion-col size="12">
        <ion-item lines="none" class="modal-ion-item">
          <ion-label class="modal-label">
            <ion-toggle [checked]="!notification.isOptOut"
              (ionChange)="changeCustomerNotification(!$event.detail.checked, notification)">
              {{ notification.customerNotificationValue }}
            </ion-toggle>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </div>

</ion-content>