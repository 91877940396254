import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { ToastButton, ToastController } from '@ionic/angular/standalone'
import { Color, ToastOptions } from '@ionic/core'
import { TranslateService } from '@ngx-translate/core'
import { CacheService } from '../business/cache.service'
import { CRBSettingKeys } from '../api/models/configuration.model'
import { PhonePipe } from '../../../../src/commons/directives/phone.pipe'

@Injectable()
export class NotificationService {
    private displayDuration = 5000
    private renderer: Renderer2

    constructor(
        private cacheService: CacheService,
        private phonePipe: PhonePipe,
        private toastController: ToastController,
        private translateService: TranslateService,
        public rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null)
    }

    public async showToasterMessage(notificationOptions: NotificationOptions): Promise<void> {
        const defaultOption: ToastOptions = {
            position: 'bottom',
            duration: this.displayDuration,
        }

        const displayOption = { ...defaultOption, ...notificationOptions }

        const toast = await this.toastController.create(displayOption)

        await toast.present()
    }

    public async showToasterErrorMessage(message: string): Promise<void> {
        //for error message we can put some default color or other default property here
        await this.showToasterMessage({ message: message })
    }

    public async showUnhandledToasterErrorMessage(): Promise<void> {
        let phoneNumber = this.cacheService.getBrandConfigSettings()?.find(q => q.keyName === CRBSettingKeys.BankSupportPhoneNumber)?.keyValue

        if (phoneNumber)
            phoneNumber = this.phonePipe.transform(phoneNumber)
        else
            phoneNumber = this.translateService.instant('commons.errorMessage.phone') as string

        await this.showToasterMessage(
            {
                showCloseButton: true,
                position: 'top',
                duration: 30000,
                cssClass: 'errorMessageToast',
                message: this.translateService.instant(
                    'commons.errorMessage.message', { 'programPhoneNumber': phoneNumber }
                ) as string,
                buttons: [
                    {
                        side: 'end',
                        icon: 'close-outline'
                    }
                ],
            }
        )
    }

    public showInlineMessage(element: ElementRef, message?: string): void {
        if (message) {
            this.renderer.setProperty(element.nativeElement, 'innerHTML', message)
        }
        element.nativeElement.hidden = false

        setTimeout(() => {
            element.nativeElement.hidden = true
        }, this.displayDuration)
    }
}

export interface NotificationOptions {
    header?: string
    message?: string
    position?: 'top' | 'bottom' | 'middle'
    duration?: number
    cssClass?: string | string[]
    color?: Color
    showCloseButton?: boolean
    buttons?: (string | ToastButton)[] | undefined
}
