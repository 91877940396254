import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular/standalone'
import { PlatformService } from '../services/business/platform.service'
import { CacheService } from '../services/business/cache.service'
import { CRBConfigSettingDto, CRBSettingKeys } from '../services/api/models/configuration.model'
import { addIcons } from 'ionicons'
import { closeCircleOutline } from 'ionicons/icons'

@Component({
    selector: 'html-privacy-policy-modal',
    templateUrl: './html-privacy-policy-modal.component.html',
    styleUrls: ['./html-privacy-policy-modal.component.scss'],
})
export class HtmlPrivacyPolicyModalComponent {
    public modalHtmlUrl = ''
    public safeResourceUrl: SafeResourceUrl

    privacyPolicyLinks: CRBConfigSettingDto[]

    crbPrivacyNotice: CRBConfigSettingDto
    programPrivacyNotice: CRBConfigSettingDto
    privacyPolicyType: PrivacyNoticeType
    crbConfigName: string

    constructor(public platform: PlatformService,
        public modalController: ModalController,
        public cacheService: CacheService,
        public sanitizer: DomSanitizer) {

        setTimeout(() => {
            this.programPrivacyNotice = this.privacyPolicyLinks.find(q => q.keyName == CRBSettingKeys.ProgramPrivacyPolicy)
            this.crbPrivacyNotice = this.privacyPolicyLinks.find(q => q.keyName == CRBSettingKeys.CRBPrivacyPolicy)

            this.showPrivaceNotice(PrivacyNoticeType.SightlinePayments)
            const config = cacheService.getCRBConfig()
            this.crbConfigName = config?.name?.toUpperCase()
        }, 100)
        addIcons({ closeCircleOutline })
    }

    public async dismiss(): Promise<void> {
        const modal = await this.modalController.getTop()
        if (modal) {
            await modal.dismiss()
        }
    }

    showPrivaceNotice(privaceNotice: PrivacyNoticeType): void {
        if (privaceNotice == PrivacyNoticeType.SightlinePayments) {
            this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.programPrivacyNotice.keyValue)
            this.privacyPolicyType = PrivacyNoticeType.SightlinePayments
        } else {
            this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.crbPrivacyNotice.keyValue)
            this.privacyPolicyType = PrivacyNoticeType.CRB
        }
    }
}

export enum PrivacyNoticeType {
    SightlinePayments = 1,
    CRB = 2
}
