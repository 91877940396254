import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { OktaAuthModule } from '@okta/okta-angular'
import { AuthGuard } from './services/auth/auth-guard.service'
import { AuthWrapperService } from './services/auth/auth-wrapper.service'
import { AuthInterceptor } from './services/auth/auth.interceptor.service'
import { UserApiService } from './services/users/userApi.service'
import { PasswordService } from './services/passwordRules/password.service'

@NgModule({ imports: [CommonModule,
        RouterModule,
        OktaAuthModule.forRoot()], providers: [
        AuthWrapperService,
        PasswordService,
        UserApiService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SecurityModule { }
