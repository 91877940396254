/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Component, OnInit } from '@angular/core'
import { LoadingController, ModalController } from '@ionic/angular'
import { addIcons } from 'ionicons'
import { closeCircleOutline } from 'ionicons/icons'
import { ApiService } from '../services/api/api.service'
import { CustomerNotificationOptOutDto } from '../models/customer-notification'
import { NotificationService } from '../services/ui/notification.service'

@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.scss'],
})
export class NotificationManagementComponent implements OnInit {

  customerNotifications: CustomerNotificationOptOutDto[] = []
  customerNotificationsBkp: CustomerNotificationOptOutDto

  loadingMessage: HTMLIonLoadingElement
  loading = true
  isLoading: any

  constructor(public modalController: ModalController,
    public notificationService: NotificationService,
    public loadingController: LoadingController,
    public apiService: ApiService
  ) {
    addIcons({ closeCircleOutline })
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loading = true
      this.customerNotifications = await this.apiService.getCustomerNotifications()
      this.customerNotificationsBkp = JSON.parse(JSON.stringify(this.customerNotifications))
    } catch {
      void this.notificationService.showUnhandledToasterErrorMessage()
    } finally {
      this.loading = false
    }
  }


  public async dismissModal(): Promise<void> {
    const modal = await this.modalController.getTop()
    if (modal) {
      await modal.dismiss()
    }
  }

  async changeCustomerNotification(isOptOut: boolean, customerNotification: CustomerNotificationOptOutDto) {
    if (this.isLoading) {
      return // Prevent re-entry
    }

    try {
      this.isLoading = true
      await this.presentLoadingMessage()
      customerNotification.isOptOut = isOptOut
      await this.apiService.postCustomerNotifications([customerNotification])
    }
    catch {
      void this.notificationService.showUnhandledToasterErrorMessage()
    } finally {
      await this.dismissLoadingMessage()
      this.isLoading = false
    }
  }

  private async presentLoadingMessage() {
    this.loadingMessage = await this.loadingController.create({
      message: 'Please wait...',
    })

    await this.loadingMessage.present()
  }

  private async dismissLoadingMessage() {
    await this.loadingMessage.dismiss()
  }
}
