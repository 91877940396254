export class FooterLink {
  id: number
  key: string
  type: FooterLinkType
  name: string
  url: string
}

export enum FooterLinkType {
  HtmlContent = 0,
  Link = 1,
  PrivacyPolicy = 2
}