
  <div class="ion-page" *ngIf="componentLoaded">
    <ion-header>
      <ion-toolbar color="tertiary">
        <ion-title>{{ "commons.themeConfiguration.title" | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content aria-label="Theme configurator"> 
      <ion-grid class="space-grid">
        <ion-row>
          <ion-col>
            <form *ngIf="brandColors" class="colorForm" [formGroup]="colorForm">

              <h6>{{ "commons.themeConfiguration.sections.colors" | translate }}</h6>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.bottomNavigationColor" | translate }}</mat-label>
                <input matInput formControlName="bottomNavigationColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="bottomNavigationColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['bottomNavigationColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.globalHeaderColor" | translate }}</mat-label>
                <input matInput formControlName="globalHeaderColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="globalHeaderColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['globalHeaderColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.callToActionColor" | translate }}</mat-label>
                <input matInput formControlName="callToActionColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="callToActionColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['callToActionColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.secondaryFunctionColor" | translate }}</mat-label>
                <input matInput formControlName="secondaryFunctionColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="secondaryFunctionColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['secondaryFunctionColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.moduleHeaderColor" | translate }}</mat-label>
                <input matInput formControlName="moduleHeaderColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="moduleHeaderColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['moduleHeaderColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.socialMediaIconColor" | translate }}</mat-label>
                <input matInput formControlName="socialMediaIconColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="socialMediaIconColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['socialMediaIconColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.topNavigationBarColor" | translate }}</mat-label>
                <input matInput formControlName="topNavigationBarColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="topNavigationBarColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['topNavigationBarColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.activeNavFunctionColor" | translate }}</mat-label>
                <input matInput formControlName="activeNavFunctionColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="activeNavFunctionColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['activeNavFunctionColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width">
                <mat-label>{{ "commons.themeConfiguration.colors.dividerLineColor" | translate }}</mat-label>
                <input matInput formControlName="dividerLineColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="dividerLineColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['dividerLineColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="full-width" style="display: none;">
                <mat-label>{{ "commons.themeConfiguration.colors.mainTextColor" | translate }}</mat-label>
                <input matInput formControlName="mainTextColorInputCtrl" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="mainTextColorPickerCtrl"
                ></ngx-colors>
                <mat-error
                  *ngIf="this.colorForm.controls['mainTextColorInputCtrl'].hasError('invalidColor')"
                >
                  The color is invalid.
                </mat-error>
              </mat-form-field>

              <hr />
              <h6>{{ "commons.themeConfiguration.sections.images" | translate }}</h6>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.favicon" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="faviconFileInput" accept=".png, .svg">
                </ngx-mat-file-input>
                <div *ngIf="faviconFileId" class="preview"><img class="preview-image" [src]="faviconFilePreviewBase64"/></div>
                <ion-icon *ngIf="faviconFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('favicon')"></ion-icon>
                <mat-hint>70x70px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.logo" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="logoFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="logoFileId" class="preview"><img class="preview-image" [src]="logoFilePreviewBase64"/></div>
                <ion-icon *ngIf="logoFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('logo')"></ion-icon>
                <mat-hint>220x61px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.card" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="cardArtFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="cardArtFileId" class="preview"><img class="preview-image" [src]="cardArtFilePreviewBase64"/></div>
                <ion-icon *ngIf="cardArtFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('cardArt')"></ion-icon>
                <mat-hint>433x285px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.logoWhite" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="logoWhiteFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="logoWhiteFileId" class="preview"><img class="preview-image" [src]="logoWhiteFilePreviewBase64"/></div>
                <ion-icon *ngIf="logoWhiteFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('logoWhite')"></ion-icon>
                <mat-hint>110x70px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.registerBackground" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="registerBackgroundFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="registerBackgroundFileId" class="preview"><img class="preview-image" [src]="registerBackgroundFilePreviewBase64"/></div>
                <ion-icon *ngIf="registerBackgroundFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('registerBackground')"></ion-icon>
                <mat-hint>1865x1243px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.homeBackground" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="homeBackgroundFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="homeBackgroundFileId" class="preview"><img class="preview-image" [src]="homeBackgroundFilePreviewBase64"/></div>
                <ion-icon *ngIf="homeBackgroundFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('homeBackground')"></ion-icon>
                <mat-hint>1865x1243px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.loginWatermark" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="loginWatermarkFileInput" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <div *ngIf="loginWatermarkFileId" class="preview"><img class="preview-image" [src]="loginWatermarkFilePreviewBase64"/></div>
                <ion-icon *ngIf="loginWatermarkFileId" class="remove-button" name="remove-circle-outline" (click)="removeFile('loginWatermark')"></ion-icon>
                <mat-hint>638x638px</mat-hint>
              </mat-form-field>

              <mat-form-field class="file-form">
                <mat-label>{{ "commons.themeConfiguration.files.marketingBanner" | translate }}</mat-label>
                <ngx-mat-file-input [formControl]="marketingBannerFileInput" [multiple]="true" accept=".png, .jpg, .jpeg, .webp, .svg">
                </ngx-mat-file-input>
                <mat-hint>{{ "commons.themeConfiguration.files.marketingBannerHint" | translate }}</mat-hint>
              </mat-form-field>
              <div class="marketing-section" *ngFor="let marketingImage of marketingBannerImages">
                <div><img class="preview-image" [src]="marketingImage.url"/></div>
                <ion-icon class="remove-marketing-button" name="remove-circle-outline" (click)="removeMarketingFile(marketingImage.rId)"></ion-icon>
              </div>

              <hr />
              <h6>{{ "commons.themeConfiguration.sections.files" | translate }}</h6>

              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.programFAQ" | translate }}</mat-label>
                <input matInput formControlName="programFAQInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.programDepositAgreement" | translate }}</mat-label>
                <input matInput formControlName="programDepositAgreementInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.programPrivacyPolicy" | translate }}</mat-label>
                <input matInput formControlName="programPrivacyPolicyInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.portalFAQ" | translate }}</mat-label>
                <input matInput formControlName="portalFAQInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.websiteTermsOfUse" | translate }}</mat-label>
                <input matInput formControlName="websiteTermsOfUseInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.files.crbPrivacyPolicy" | translate }}</mat-label>
                <input matInput formControlName="cRBPrivacyPolicyInput" />
              </mat-form-field>

              <hr />
              <h6>{{ "commons.themeConfiguration.sections.settings" | translate }}</h6>

              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.bankSupportPhoneNumber" | translate }}</mat-label>
                <input matInput formControlName="bankSupportPhoneNumberInput" />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.bankDisclosure" | translate }}</mat-label>
                <textarea matInput formControlName="bankDisclosureInput" rows="4"></textarea>
              </mat-form-field>
              <mat-checkbox formControlName="isOperatorSiteLinkEnabledInput">{{ "commons.themeConfiguration.settings.isOperatorSiteLinkEnabled" | translate }}</mat-checkbox>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.operatorSiteLinkVerbiage" | translate }}</mat-label>
                <textarea matInput formControlName="operatorSiteLinkVerbiageInput" rows="2"></textarea>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.operatorSiteLinkUrl" | translate }}</mat-label>
                <textarea matInput formControlName="operatorSiteLinkUrlInput" rows="2"></textarea>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.operatorSiteLinkText" | translate }}</mat-label>
                <input matInput formControlName="operatorSiteLinkTextInput" />
              </mat-form-field>
              <mat-checkbox formControlName="isTransferToBankEnabledInput">{{ "commons.themeConfiguration.settings.isTransferToBankEnabled" | translate }}</mat-checkbox>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.externalBankTransferInfoHeader" | translate }}</mat-label>
                <textarea matInput formControlName="externalBankTransferInfoHeaderInput" rows="2"></textarea>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "commons.themeConfiguration.settings.externalBankTransferInfoBody" | translate }}</mat-label>
                <textarea matInput formControlName="externalBankTransferInfoBodyInput" rows="4"></textarea>
              </mat-form-field>
            </form>
          </ion-col>
        </ion-row>
        <!-- <ion-row>
          <ion-col>
            <ion-button id="applyButton" expand="block" (click)="publishConfiguration()">
              {{ 'commons.themeConfiguration.publish' | translate }}
            </ion-button>
          </ion-col>
        </ion-row> -->
      </ion-grid>
    </ion-content>
  </div>
