import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { Utils } from '../utils/utils'
import { TranslateService } from '@ngx-translate/core'
import { addIcons } from 'ionicons'
import { chevronBack } from 'ionicons/icons'

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb'
    menuItems: MenuItem[]

    @Input() customText = ''

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        public translateService: TranslateService) {
        addIcons({ chevronBack })
    }

    ngOnInit(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
    }

    goBack(): void {
        window.history.back()
    }

    public createBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
        const children: ActivatedRoute[] = route.children

        if (children.length === 0) {
            return breadcrumbs
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/')
            if (routeURL !== '') {
                url += `/${routeURL}`
            }

            const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB] as string
            if (!Utils.isNullOrUndefined(label)) {
                const translatedLabel = this.translateService.instant(label) as string
                breadcrumbs.push({ label: translatedLabel, url: url })
            }

            this.createBreadcrumbs(child, url, breadcrumbs)
        }

        return breadcrumbs
    }
}

export class MenuItem {
    label: string
    url: string
}
