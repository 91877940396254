<ion-app *ngIf="appLoaded">
  <ion-split-pane when="md" contentId="main" [disabled]="previewModeType !== 'Edit'">
    <app-style-configurator *ngIf="previewMode"
      (previewModeLoadedEvent)="onPreviewModeLoaded()"></app-style-configurator>
    <div class="ion-page" id="main">
      <ion-icon *ngIf="previewMode && previewModeType === 'Edit'" name="color-palette-outline"
        class="theme-configurator-icon" (click)="openThemeConfiguratorMenu()"></ion-icon>
      <ion-content *ngIf="!previewMode || (previewMode && previewModeLoaded)" role="none" id="main-container" attr.aria-label="{{ 'commons.mainContainer' | translate }}">
        <ion-header *ngIf="showMenu()">
          <app-header>
          </app-header>
        </ion-header>
        <app-mobile-menu role="menubar" *ngIf="showMenu()"></app-mobile-menu>
        <ion-router-outlet role="none" id="router-container" [ngClass]="{'margin-header': showMenu()}"></ion-router-outlet>
        <tab-monitor></tab-monitor>
      </ion-content>
    </div>
  </ion-split-pane>
  <ion-menu side="end" menuId="theme-configurator-menu" *ngIf="previewMode" contentId="main" class="theme-mobile-menu">
    <ion-menu-toggle menu="end">
      <ion-icon class="theme-configurator-close-icon" name="close-circle-outline"></ion-icon>
    </ion-menu-toggle>
    <ion-content role="none" class="ion-padding">
      <app-style-configurator (previewModeLoadedEvent)="onPreviewModeLoaded()"></app-style-configurator>
    </ion-content>
  </ion-menu>
</ion-app>